import $ from "jquery";

function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

function hasNumbers(t) {
    var regex = /\d/g;
    return regex.test(t);
}

function isNumeric(str) {
  if (typeof str != "string"){
    return false
  }
  return /*!isNaN(str) */!isNaN(parseFloat(str))
}

function hasUpper(string) {
    var i = 0;
    var character = '';
    while (i <= string.length) {
        character = string.charAt(i);
        if (!isNaN(character * 1)) {} else {
            if (character === character.toUpperCase()) {
                if (!isNumeric(character) && character.match(/[a-z]/i)){
                    return true;
                }
            }
        }
        i++;
    }
}

export var MainFunc = {
	trainer_or_pokemons_open_lists(open_pokemons=0, open_battles=0){
		var content_h = 0;
		var trainer_export_button = $('.content__box-mobile > .k_cart_trainer_export');
		var content_pokemon_or_trainer = $('.content__box-desc');
		var exit_auth = $('.k_trainer_exit');
		var collumn_2_buttons = $('.k_premium_button_fix, .k_profile_trainer');

		var list_pokemons = $('.trainer-item__pokemons');
		var list_battles = $('.trainer-item__history');

		var content__box_pokemon_top = $('.content__box_pokemon_top');

		var e = false;
		if ((list_pokemons.hasClass('list-open') || open_pokemons === 1) && open_pokemons !== 2) {
			e = list_pokemons.find('.pokemons-top');

			if (list_battles.css('display') !== 'none') {
				list_battles.css('display', 'none');
			}
		}else{
			if (list_battles.css('display') === 'none') {
				list_battles.css('display', 'flex');
			}
		}

		if ((list_battles.hasClass('list-open') || open_battles === 1) && open_battles !== 2) {
			e = list_battles.find('.history-top');

			if (list_pokemons.css('display') !== 'none') {
				list_pokemons.css('display', 'none');
			}
		}else{
			if (list_pokemons.css('display') === 'none') {
				list_pokemons.css('display', 'flex');
			}
		}

		if ((list_pokemons.hasClass('list-open') || list_battles.hasClass('list-open') || open_pokemons === 1 || open_battles === 1) && open_battles !== 2 && open_pokemons !== 2) {
			collumn_2_buttons.each(function (){
				if ($(this).css('visibility', 'visible')){
					$(this).css('display', 'none');
				}
			});
		}else{
			collumn_2_buttons.each(function (){
				if ($(this).css('visibility', 'visible')){
					$(this).css('display', 'flex');
				}
			});
		}

		if (window.innerWidth < 1600){
			if ((list_pokemons.hasClass('list-open') || list_battles.hasClass('list-open') || open_pokemons === 1 || open_battles === 1) && open_battles !== 2 && open_pokemons !== 2){
				if (content_pokemon_or_trainer.css('display') !== 'none'){
					content_pokemon_or_trainer.css('display', 'none');
				}
				if (window.innerWidth <= 992){
					if (exit_auth.css('display') !== 'none') {
						exit_auth.css('display', 'none');
					}
				}

				if (trainer_export_button.css('display') === 'flex' ) {
					trainer_export_button.css('display', 'none');
				}

				if (content__box_pokemon_top.css('display') === 'flex' ) {
					content__box_pokemon_top.css('display', 'none');
				}
			}else{
				if (window.innerWidth <= 992){
					if (exit_auth.css('display') === 'none') {
						exit_auth.css('display', 'block');
					}
				}

				if (content_pokemon_or_trainer.css('display') === 'none'){
					content_pokemon_or_trainer.css('display', 'flex');
				}

				if (trainer_export_button.css('visibility', 'visible')){
					trainer_export_button.css('display', 'flex');
				}

				if (content__box_pokemon_top.css('display') === 'none' ) {
					content__box_pokemon_top.css('display', 'flex');
				}
			}
		}else{
			if (content_pokemon_or_trainer.css('display') === 'none'){
				content_pokemon_or_trainer.css('display', 'flex');
				$('.trainer-item .content__inner').css('gap', '');
			}

			if (trainer_export_button.css('display') === 'flex' ) {
				trainer_export_button.css('display', 'none');
			}

			if (content__box_pokemon_top.css('display') === 'none' ) {
				content__box_pokemon_top.css('display', 'flex');
			}
		}

		if ((list_pokemons.hasClass('list-open') || list_battles.hasClass('list-open') || open_pokemons === 1 || open_battles === 1) && open_battles !== 2 && open_pokemons !== 2) {
			if (window.innerHeight > 500) {
				content_h = window.innerHeight - ($('header').outerHeight() + $('footer').outerHeight() + $('.title-single').outerHeight() + $(e).outerHeight() + 36 + 12 - 27)

				if (window.innerWidth <= 992) {
					content_h -= 100;
				}

				if (content_h < 372) {
					content_h = 372
				} else {
					if ($('section.trainer-item.single-trainer').css('margin-bottom') !== '0px'){
						$('section.trainer-item.single-trainer').css('margin-bottom', '0px');
					}
				}
			}
		}

		return content_h;
	},
	render_404(){
		$('main').html('<div class="gradient"></div><div class="not-found container"><div class="not-found__container"><h2 class="not-found__title">Такая страница не&nbsp;найдена</h2><div class="not-found__status"><img src="/static/media/error-status.547e96d9d674377a44f8.png" class="not-found__status-bg" alt="облако"><h1 class="not-found__text">404...</h1></div><img src="/static/media/error-img.da106fd05b43b3662cb4.png" class="not-found__pikachu" alt="пикачу"></div></div>');
	},
	isNumber(n) {
		return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
	},
	replaceAt(s, n, t) {
		return s.substring(0, n) + t + s.substring(n + 1);
	},
	appendAt(s, n, t) {
		return s.substring(0, n) + t + s.substring(n);
	},
	k_get: function(name) {
		if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
			return decodeURIComponent(name[1]);
	},
	check_button_top: function(class_button, class_parent, height){
		if ($(window).width() < 460) {
			var parent_height = $('.'+class_button).closest('.'+class_parent).outerHeight();
			var window_height = $(window).outerHeight();
			var add_height = $('.'+class_button).closest('.profile').find('.profile-mobile').height();

			console.log(parent_height);
			console.log(add_height);
			console.log(window_height);
			console.log((window_height - (parent_height + add_height)));

			if ((window_height - (parent_height + add_height)) <= height + 120){
				console.log('ok');
				$('.'+class_button).css('position', 'relative');
				$('.'+class_button).css('bottom', '0px');
				$('.'+class_button).css('padding', '2px 0px 24px');
			}else{
				$('.'+class_button).css('position', 'absolute');
				$('.'+class_button).css('bottom', '24px');
				$('.'+class_button).css('padding', '0 12px');
			}
		}
	},
	k_number_price_format: function(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	},
	k_check_field: function(k_class_obj, ktype, pass_rename = false){
	    if (ktype == 'text'){
	        if (  $(k_class_obj).val() == '' ){
	             $(k_class_obj).addClass('k_f_error');
	             $(k_class_obj).parent().find('.auth__error').fadeIn();
	            return false;
	        }else{
	             $(k_class_obj).removeClass('k_f_error');
	             $(k_class_obj).parent().find('.auth__error').fadeOut();
	            return true;
	        }
	    }
	    if (ktype == 'email'){
	        if (  $(k_class_obj).val() == '' || isEmail( $(k_class_obj).val()) == false ){
	             $(k_class_obj).addClass('k_f_error');
	             $(k_class_obj).parent().find('.auth__error').fadeIn();
	            return false;
	        }else{
	             $(k_class_obj).removeClass('k_f_error');
	             $(k_class_obj).parent().find('.auth__error').fadeOut();
	            return true;
	        }
	    }
	    if (ktype == 'pass'){
	        var has_num = hasNumbers( $(k_class_obj+'_1').val());
	        var has_Upper = hasUpper( $(k_class_obj+'_1').val());
	        if (
	             $(k_class_obj+'_1').val() == '' ||
	             $(k_class_obj+'_1').val().length < 6 ||
	            !has_num ||
	            !has_Upper
	        ){
	             $(k_class_obj+'_1').addClass('k_f_error');
	             $(k_class_obj+'_1').parent().find('.auth__error').fadeIn();

	            if ( $(k_class_obj+'_1').val() != ''){
	                 $(k_class_obj+'_1').parent().find('.auth__error').html('Пароль не соответствует требованиям');
	            }else{
	                 $(k_class_obj+'_1').parent().find('.auth__error').html('Введите пароль');
	            }

	            if (pass_rename &&  $(k_class_obj+'_1').val() != ''){
	                 $(k_class_obj+'_1').parent().find('.auth__error').html('Новый пароль не соответствует требованиям.<br>Минимум 6 символов, 1 заглавная буква и 1 цифра');
	            }

	            return false;
	        }else{
	             $(k_class_obj+'_1').removeClass('k_f_error');
	             $(k_class_obj+'_1').parent().find('.auth__error').fadeOut();

	            if (
	                 $(k_class_obj+'_2').val() != '' &&
	                 $(k_class_obj+'_2').val() ==  $(k_class_obj+'_1').val()
	            ){
	                 $(k_class_obj+'_2').removeClass('k_f_error');
	                 $(k_class_obj+'_2').parent().find('.auth__error').fadeOut();
	                return true;
	            }else{
	                 $(k_class_obj+'_2').addClass('k_f_error');
	                 $(k_class_obj+'_2').parent().find('.auth__error').fadeIn();
					 $(k_class_obj+'_2').parent().find('.auth__error').html('Пароли не совпадают');
	                return false;
	            }
	        }
	    }
	}
}
