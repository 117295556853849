import React, { useState } from "react";
import arrowLeft from "../../images/arrow-left.svg";

import "../Auth/Auth.css";
import "../Auth/Login/Login.css";
import "./Profile.css";
import Setting from "../Setting/Setting";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import $ from "jquery";
import site_config from "../kenshi_config/kenshi_config";
import Cookies from "js-cookie";
import kenshi_config from "../kenshi_config/kenshi_config";
import { useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {MainFunc} from "../kenshi_config/kenshi_functions";
import KenshiConfig from "../kenshi_config/kenshi_config";

function k_get(name){
  if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
    return decodeURIComponent(name[1]);
}

$('body').on('click', '.k_google_connect .delete_method', function (){
  $.ajax({
    url: site_config.new_api + '/technical_routes/auth_control',
    type: 'POST',
    dataType: 'json',
    data: {action: 'delete_vk', trainer_token: Cookies.get('session_id')},
    success: function (data) {
      if (data.status == 'success') {
        Cookies.set('vk_auth_id', null, { expires: 365 });

        k_render_profile();
      }
    }
  });
});

$('body').on('click', '.k_telegram_connect .delete_method', function (){
  $.ajax({
    url: site_config.new_api + '/technical_routes/auth_control',
    type: 'POST',
    dataType: 'json',
    data: {action: 'delete_t', trainer_token: Cookies.get('session_id')},
    success: function (data) {
      if (data.status == 'success') {
        Cookies.set('telegram_name', null, { expires: 365 });

        k_render_profile();
      }
    }
  });
});

const validateEmail = (email) => {
  return String(email)
      .toLowerCase()
      .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

$('body').on('click', '.k_send_mail_confirm', function (){
  $('.k_form_reg_mail_error').parent().find('.auth__error').fadeOut();
  let k_error = 0;

  if ( k_error === 0) {
    if ($('.k_input_mail_confirm').val() !== '') {
      $('.k_input_mail_confirm').parent().find('.auth__error').fadeOut();
    } else {
      $('.k_input_mail_confirm').parent().find('.auth__error').html('Введите почту');
      $('.k_input_mail_confirm').parent().find('.auth__error').fadeIn();
      return
    }

    if (validateEmail($('.k_input_mail_confirm').val())) {
      $('.k_input_mail_confirm').parent().find('.auth__error').fadeOut();
    } else {
      $('.k_input_mail_confirm').parent().find('.auth__error').html('Введите корректную почту');
      $('.k_input_mail_confirm').parent().find('.auth__error').fadeIn();
      return
    }

    $.ajax({
      url: site_config.new_api + '/technical_routes/auth_control',
      type: 'POST',
      dataType: 'json',
      data: {action: 'email_send_profile', email: $('.k_input_mail_confirm').val(), trainer_token: Cookies.get('session_id'), user_id: Cookies.get('user_id'), pass_1: $('.k_form_reg_pass_1').val(), pass_2: $('.k_form_reg_pass_2').val()},
      success: function (data) {
        if (data.message === 'Успешно') {
          Cookies.set('login', $('.k_input_mail_confirm').val(), {expires: 365});
          $('.k_input_mail_confirm').parent().find('.auth__error').fadeOut();

          k_render_profile();
        }
      },
      error: function (data){
        data = JSON.parse(data.responseText)
        console.log(data)

        var show_pass = false;

        if(data.message === 'Аккаунт с такой почтой уже зарегистрирован'){
          $('.k_form_reg_mail_error').text('Аккаунт с такой почтой уже зарегистрирован');
          $('.k_form_reg_mail_error').fadeIn();

          show_pass = true;
        }

        if(show_pass === false){
          $('.k_form_reg_pass_1').parent().find('.auth__error').text(data.message);
          $('.k_form_reg_mail_error').parent().find('.auth__error').fadeIn();
        }
      }
    });
  }

  return false;
});

$('body').on('click', '.k_send_mail_renew', function (){
  if($('.k_input_mail_renew').val() !== ''){
    $('.k_input_mail_renew').parent().find('.auth__error').fadeOut();
  }else{
    $('.k_input_mail_renew').parent().find('.auth__error').html('Введите почту');
    $('.k_input_mail_renew').parent().find('.auth__error').fadeIn();
    return;
  }

  if(validateEmail($('.k_input_mail_renew').val())){
    $('.k_input_mail_renew').parent().find('.auth__error').fadeOut();
  }else{
    $('.k_input_mail_renew').parent().find('.auth__error').html('Введите корректную почту');
    $('.k_input_mail_renew').parent().find('.auth__error').fadeIn();
    return;
  }

  $.ajax({
    url: site_config.new_api + '/technical_routes/auth_control',
    type: 'POST',
    dataType: 'json',
    data: {action: 'email_send', email: $('.k_input_mail_renew').val(), user_id: Cookies.get('user_id')},
    success: function (data) {
      if (data.message === 'Успешно') {
        Cookies.set('login', $('.k_input_mail_renew').val(), { expires: 365 });
        $('.k_input_mail_renew').parent().find('.auth__error').fadeOut();

        k_render_profile();
      }
    },
    error: function (data){
      data = JSON.parse(data.responseText);
      $('.k_input_mail_renew').parent().find('.auth__error').html(data.message);
      $('.k_input_mail_renew').parent().find('.auth__error').fadeIn();
    }
  });

  return false;
});

if (k_get('hash')){
  $.ajax({
    url: site_config.new_api + '/technical_routes/auth_control',
    type: 'POST',
    dataType: 'json',
    data: {action: 'email_check', email: Cookies.get('login'), client_key: k_get('hash')},
    success: function (data) {
      if (data.message === 'Успешно') {
        Cookies.set('email_confirm', true, { expires: 365 });
        $('.k_pochta_success').css('display', 'block');
        $('.k_pochta_pass').html(data.password);

        k_render_profile();
        const url = new URL(document.location);
        const searchParams = url.searchParams;
        searchParams.delete("hash");
        searchParams.delete("trainer_id");
        window.history.pushState({}, '', url.toString());
      }
    },
    error: function (data){
      data = JSON.parse(data.responseText);
      $('.k_pochta_error .k_pochta_error_m').html(data.message);
      $('.k_pochta_error').css('display', 'block');
    }
  });
}


function k_render_profile(){
  $.ajax({
    url: site_config.new_api + '/technical_routes/auth_control',
    type: 'POST',
    dataType: 'json',
    data: {action: 'auth', api_key: Cookies.get('session_id'), firebase_token: Cookies.get('firebase_token')},
    success: function (data) {
      $('body').css('visibility', 'visible');
      if (data.hash) {
        Cookies.set('user_id', data.id, { expires: 365 });
        Cookies.set('user_image', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data.avatar_id).padStart(2, '0')+'.png', { expires: 365 });

        Cookies.set('login', data.login, { expires: 365 });
        Cookies.set('vk_auth_id', data.vk_auth_id, { expires: 365 });
        Cookies.set('user_auth', '1', { expires: 365 });
        Cookies.set('is_premium', data.is_premium, { expires: 365 });
        Cookies.set('premium_duration', data.premium_duration, { expires: 365 });

        Cookies.set('email_confirm', data.email_confirm, { expires: 365 });
        setTimeout(function (){
          var url_to_google = 'https://id.vk.com/auth?uuid='+kenshi_config.tech_api_vk_secret_public_hash+'&app_id='+kenshi_config.tech_api_vk_app_id+'&response_type=silent_token&redirect_uri='+KenshiConfig['new_api']+'/technical_routes/callback_vk';

          var none_p = '<p class="profile__subtitle text-center">Невозможно отвязать<br /> единственный способ</p>';
          var button_disconnect = '<div type="submit" class="profile__button profile__button-right profile__button-light delete_method">Отвязать</div>';
          var button_connect = '<div type="submit" class="profile__button profile__button-right">Привязать</div>';

          $('.k_google_connect').html('')
          $('.k_email_connect').html('')

          var max_methods = 0;
          if (Cookies.get('login') && Cookies.get('login') !== 'null' && Cookies.get('email_confirm') && Cookies.get('email_confirm') !== 'null' && Cookies.get('email_confirm') !== 'false'){
            max_methods += 1;
          }

          if (Cookies.get('vk_auth_id') && Cookies.get('vk_auth_id') !== 'null'){
            max_methods += 1;
          }

          if (Cookies.get('vk_auth_id') && Cookies.get('vk_auth_id') !== 'null'){
            $('.k_google_name').html(Cookies.get('vk_auth_id'));
            $('.k_google_name').removeClass('profile__text-grey');
            $('.k_google_connect').html('');

            if (max_methods <= 1) {
              $('.k_google_connect').append(none_p)
            }else{
              $('.k_google_connect').append(button_disconnect)
            }
          }else{
            $('.k_google_name').html('Не привязан');
            $('.k_google_name').addClass('profile__text-grey');
            $('.k_google_connect').html('');
            $('.k_google_connect').append(button_connect)
            $('.k_google_connect > div').off('click');
            $('.k_google_connect > div').on('click', function (){
              window.location.href = url_to_google;
            });
            Cookies.set('google_add', 'true', { expires: 100 });
          }

          if (Cookies.get('login') && Cookies.get('login') !== 'null' && Cookies.get('email_confirm') && Cookies.get('email_confirm') !== 'null' && Cookies.get('email_confirm') !== 'false'){
            $('.k_email_name').html(Cookies.get('login'));
            $('.k_email_name').removeClass('profile__text-grey');
            $('.k_email_connect').html('');
            $('.k_email_connect').append('<p class="profile__subtitle text-center">Невозможно отвязать</p>');
          }else{
            $('.k_email_name').html('Не привязан');
            $('.k_email_name').addClass('profile__text-grey');
            console.log(Cookies.get('email_confirm'));
            if (Cookies.get('email_confirm') === false || Cookies.get('email_confirm') === 'false' || Cookies.get('email_confirm') === 'null' && Cookies.get('login') && Cookies.get('login') !== 'null'){
              $('.k_profile_email_register').css('display', 'none');
              $('.k_profile_email_confirm').css('display', 'block');
            }else{
              $('.k_profile_email_confirm').css('display', 'none');
              $('.k_profile_email_register').css('display', 'block');
            }

            $('.k_profile_email_ok').css('display', 'none');
          }

          if (k_get('error')){
            $('.k_pochta_error .k_pochta_error_m').html('Привязка аккаунта<br>'+k_get('message'));
            $('.k_pochta_error').css('display', 'block');

            const url = new URL(document.location);
            const searchParams = url.searchParams;
            searchParams.delete("error");
            searchParams.delete("message");
            window.history.pushState({}, '', url.toString());
          }
        }, 500);
      }else{
        window.location.href = '/login';
        Cookies.set('user_auth', '0', { expires: 365 });
      }
    },
    error: function (data){
      window.location.href = '/login';
      Cookies.set('user_auth', '0', { expires: 365 });
    }
  });
}

export default function Profile({ status }) {
  k_render_profile();
  let navigate = useNavigate();
  const {
    register,
    getValues,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm({
    mode: "onSubmit",
  });

  const [toggle, setToggle] = useState(true);
  const handleChange = (event) => {
    const input = event.target;
    const icon = input.closest('.auth__wrap').querySelector('.auth__input-icon');
    icon.style.display='block';
    if(input.value.length == 0) {
      icon.style.display='none';
    }
  }

  const handleClick = (event) => {
    const icon = event.target;
    const input = icon.closest('.auth__wrap').querySelector('.auth__input-pass');
    if(toggle) {
      input.type = 'text';
      icon.classList.add('view-pass');
    }  else {
      input.type = 'password';
      icon.classList.remove('view-pass');
    }
    setToggle(!toggle);
  }

  $('#password, .k_form_f_pass_2').off('change');
  $('#password, .k_form_f_pass_2').on('change', function (){
    MainFunc.k_check_field('.k_form_f_pass', 'pass')
  });

  return (
      <>
        <Header />
        <main className="content">
          <div className="gradient auth_gradient"></div>
          <div onClick={() => navigate(-1)} className="profile__back-button">
            <img src={arrowLeft} alt="вернуться назад" />
            <span className="profile__back-text">Назад</span>
          </div>
          <section className="auth profile">
            <div className="login__content profile">
              <div className="profile-mobile">
                <div onClick={() => navigate(-1)} className="profile__back-button-mobile">
                  <img src={arrowLeft} alt="вернуться назад" />
                </div>
                <h2 className="profile__title-mobile">Настройки входа</h2>
              </div>

              <h1 className="profile__title">Настройки входа</h1>

              <div className="profile__container p-22 k_pochta_success" style={{ display: "none" }}>
                <div>
                  <div className="k_pochta_ok">Почта успешно подтверждена</div>
                </div>
              </div>

              <div className="profile__container p-22 k_pochta_error" style={{ display: "none" }}>
                <div>
                  <div className="k_pochta_error_m"></div>
                </div>
              </div>

              <form action="#" className="auth__form profile__email-f profile__container k_profile_email_register" noValidate={true} style={{ display: "none" }}>
                <h4 className="profile__label">Почта и пароль</h4>
                <p className="profile__subtitle mb-16">
                  Потребуется подтверждение. <br /> После подтверждения невозможно отвязать
                </p>
                <div className="auth__wrap">
                  <input type="email" className="auth__input profile__input k_input_mail_confirm" placeholder=" "/>
                  <label className="auth__label">Почта</label>
                  <span className="auth__error k_form_reg_mail_error" style={{ display: "none" }}>Введите почту</span>
                </div>
                <div className="df register__df">
                  <div className="auth__wrap">
                    <input
                        type='password'
                        id='password'
                        className="auth__input auth__input-pass k_form_reg_pass_1 k_form_f_pass_1"
                        {...register("password", {
                          required: "Введите пароль",
                          minLength: {
                            value: 6,
                            message: "Минимум 6 символов",
                          },
                        })}
                        aria-invalid={errors.password ? "true" : "false"}
                        placeholder=' '
                        onChange={handleChange}
                    />
                    <label className="auth__label">Пароль</label>

                    <span
                        className="auth__input-icon"
                        onClick={handleClick}
                    ></span>
                    <span className="auth__error">{errors.password?.message}</span>
                    <p className="auth__subtitle register__title register__title_er"
                       aria-invalid={errors.password ? "true" : "false"}>
                      Придумайте пароль. Минимум 6&nbsp;символов, 1&nbsp;заглавная буква и&nbsp;1&nbsp;цифра
                    </p>
                  </div>
                  <div className="auth__wrap">
                    <input type='password'
                           id='confirm-password'
                           className="auth__input auth__input-pass k_form_reg_pass_2 k_form_f_pass_2"
                           {...register("pass", {
                             validate: (match) => {
                               const password = getValues("password")
                               return match === password || "Пароли не совпадают"
                             },
                             required: "Пароли не совпадают",
                           })}
                           aria-invalid={errors.pass ? "true" : "false"}
                           placeholder=' '
                           onChange={handleChange}
                    />
                    <label className="auth__label">Повторите пароль</label>
                    <span
                        className="auth__input-icon"
                        onClick={handleClick}
                    ></span>
                    <span className="auth__error">{errors.pass?.message}</span>
                  </div>
                </div>
                <button type="submit" className="profile__button profile__button-right k_send_mail_confirm">
                  Привязать
                </button>
              </form>

              <form action="#" className="auth__form profile__email-f profile__container k_profile_email_confirm" noValidate={true} style={{ display: "none" }}>
                <h4 className="profile__label">Почта и пароль</h4>
                <p className="profile__subtitle mb-16">
                  Мы отправили подтверждение на вашу почту. Чтобы завершить, перейдите по ссылке в письме
                </p>
                <p className="profile__subtitle mb-16">
                  Нет письма? Проверьте папку «Спам»
                </p>
                <div className="auth__wrap" style={{ opacity: "0.6" }}>
                  <input type="email" className="auth__input profile__input k_input_mail_renew" placeholder=" "/>
                  <label className="auth__label">Почта</label>
                  <span className="auth__error" style={{ display: "none" }}>Введите почту</span>
                </div>
                <button type="submit" className="profile__button profile__button-right k_send_mail_renew">
                  Изменить
                </button>
              </form>

              <div className="profile__container p-22 flex k_profile_email_ok">
                <div>
                  <p className="profile__title-grey">Почта</p>
                  <div className="profile__text profile__text-grey k_email_name k_profile_title_left">Не привязан</div>
                </div>

                <div className='k_email_connect'></div>
              </div>

              <div action="#" className="profile__container p-22 flex">
                <div>
                  <p className="profile__title-grey">VK</p>
                  <div className="profile__text profile__text-grey k_google_name k_profile_title_left">Не привязан</div>
                </div>

                <div className='k_google_connect'></div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
  );
}
