import Copy from "../Copy/Copy";
import Level from "../Level/Level";
import "./TrainerInfo.css";
import { Link } from "react-router-dom";
import arrowRight from "../../images/icons/arrow-r.svg";
import React from "react";
const TrainerInfo = ({ img, status, name, level, copyCount, icon, icon_premium, link }) => {
  return (
    <>
      <div data-href={link} className="trainer-info df-box trainer_link_info">
        <div className="trainer-info__img">
          <img
            className="trainer-info__avatar"
            src={img}
            alt="Аватар тренера"
          />
        </div>
        <div className="trainer-info__box">
          <div className="trainer-info__user df-box">
            <h4 className="trainer-info__title">{status}</h4>
            <span className="trainer-info__subtitle">{name}</span>
            <div className={ icon ? "trainer-info__icon" : "trainer-info__icon-none"}></div>
            <div className={ icon_premium ? "trainer_info__legend" : "trainer-info__icon-none"}></div>
          </div>
          <div className="trainer-info__wrapper df-box">
            <Level level={level} name="Уровень" />
            <Copy copyCount={copyCount} />
          </div>
        </div>
        <button
            id="dropdown"
            className="btn-down btn-reset trainer-info__link">
          <img src={arrowRight} width="26" height="26" alt="история боев" />
        </button>
      </div>
    </>
  );
};

export default TrainerInfo;
