import React, {useEffect} from "react";
import logo from "../../../images/logo-studio.svg";

import "./Registration.css";
import "../Auth.css";

import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import site_config from "../../kenshi_config/kenshi_config";
import Cookies from 'js-cookie';
import $ from "jquery";
import KenshiConfig from "../../kenshi_config/kenshi_config";
import Footer from "../../Footer/Footer";

function RegistrationVK() {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: "onSubmit",
    });

    var hash = window.location.pathname.split('/')[2];

    useEffect(() => {
        if (hash.split('_-_')[0] !== 'add' && Cookies.get('google_add') !== 'true') {
            Cookies.set('session_id', hash, {expires: 365})
            $.ajax({
                url: site_config.new_api + '/technical_routes/auth_control',
                type: 'POST',
                dataType: 'json',
                crossDomain: true,
                data: {action: 'auth', api_key: Cookies.get('session_id')},
                success: function (data) {
                    if (data.hash) {
                        Cookies.set('user_id', data.id, {expires: 365});
                        Cookies.set('user_image', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data.avatar_id).padStart(2, '0')+'.png', {expires: 365})
                        setTimeout(function () {
                            $('.header__id-text_type_profile').html(Cookies.get('user_id'));
                            $('.header__id').attr('data-href', Cookies.get('user_id'));
                            $('.k_main_photo').attr('src', Cookies.get('user_image'));
                        }, 500)

                        window.location.href = '/';
                    }
                    $('body').css('visibility', 'visible');
                },
                error: function (data) {
                    data = JSON.parse(data.responseText);
                    $('body').css('visibility', 'visible');
                    $('span.auth__error').html(data.message);
                    $('span.auth__error').fadeIn();
                    $('.k_form_reg_g_token').attr('aria-invalid', "true");

                    setTimeout(function () {
                        $('.k_form_hidden').css('visibility', 'visible');
                    }, 700);
                }
            });
        } else {
            if (Cookies.get('session_id') && Cookies.get('session_id') !== 'null' && Cookies.get('session_id') !== '' && Cookies.get('google_add') === 'true') {
                Cookies.set('google_add', 'false');
                $.ajax({
                    url: site_config.new_api + '/technical_routes/auth_control',
                    type: 'POST',
                    data: {
                        trainer_token: Cookies.get('session_id'),
                        secure_hash: hash.split('_-_')[1],
                        vk_id: hash.split('_-_')[2],
                        action: 'reg_vk'
                    },
                    success: function (data) {
                        window.location.href = '/profile';
                    },
                    error: function (data) {
                        data = JSON.parse(data.responseText);
                        window.location.href = '/profile?error=google&message=' + data.message;
                    }
                });
            } else {
                setTimeout(function () {
                    $('.k_form_hidden').css('visibility', 'visible');
                }, 700);
            }
        }
    }, [])

    const onSubmit = (data) => {
        if (isValid) {
            console.log(data);

            $.ajax({
                url: site_config.new_api + '/technical_routes/auth_control',
                type: 'POST',
                data: {trainer_token: $('.k_form_reg_g_token').val(), secure_hash: hash.split('_-_')[1], vk_id: hash.split('_-_')[2], action: 'reg_vk'},
                success: function(data){
                    window.location.href = '/registration-vk/'+$('.k_form_reg_g_token').val();
                },
                error: function (data){
                    data = JSON.parse(data.responseText);
                    console.log(data);

                    $('.k_form_reg_g_token').next().next().text(data.message);
                    $('.k_form_reg_g_token').next().next().fadeIn();
                    $('.k_form_reg_g_token').attr('aria-invalid', "true");

                    //reset();
                }
            });


        }
    };

    return (
        <main className="content k_form_hidden">
            <div className="gradient auth_gradient"></div>
            <section className="auth auth-full">
                <div className="auth__content register__content">
                    <h1 className="auth__title">Битва покемонов</h1>
                    <p className="auth__title-h2 hidden">
                        Регистрация с помощью VK
                    </p>
                    <div className="auth__mobile">
                        <a href="/login" className="auth__back"></a>
                        <p className="auth__title-h2 register-hidden">
                            Регистрация через VK
                        </p>
                    </div>
                    <p className="auth__text auth__text_small" dangerouslySetInnerHTML={{__html: site_config['app_kotik_info']}}></p>
                    <form className="auth__form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="auth__wrap register__wrapp">
                            <input
                                type="text"
                                className="auth__input k_form_reg_g_token"
                                {...register("token", {
                                    required: "Недействительный токен",
                                    minLength: {
                                        value: 3,
                                        message: "Недействительный токен",
                                    },
                                })}
                                aria-invalid={errors.token ? "true" : "false"}
                                placeholder=' '
                            />
                            <label className="auth__label">Токен</label>
                            <span className="auth__error" style={{display:'none'}}>Недействительный токен</span>
                        </div>

                        <button type="submit" className="auth__button k_margin_top_15">
                            Завершить регистрацию
                        </button>
                        <Link
                            to="/login"
                            className="auth__text auth__registr autn__button-hidden"
                        >
                            Уже зарегистрированы? Войти
                        </Link>
                    </form>
                </div>
            </section>
            <div className="footer_no_line">
                <Footer />
            </div>
        </main>
    );
}

export default RegistrationVK;
