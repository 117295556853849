import React, {useState} from "react";
import VK from "../../../images/icons/VK_Logo.svg";
import telegram from "../../../images/telegram.svg";
import logo from "../../../images/logo-studio.svg";

import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";

import "../Auth.css";
import "./Registration.css";

import Cookies from 'js-cookie';
import site_config from "../../kenshi_config/kenshi_config"
import {MainFunc} from "../../kenshi_config/kenshi_functions"
import $ from "jquery";
import kenshi_config from "../../kenshi_config/kenshi_config";
import KenshiConfig from "../../kenshi_config/kenshi_config";
import Footer from "../../Footer/Footer";

const Registration = () => {
    const [toggle, setToggle] = useState(true);
    const handleChange = (event) => {
        const input = event.target;
        const icon = input.closest('.auth__wrap').querySelector('.auth__input-icon');
        icon.style.display='block';
        if(input.value.length == 0) {
            icon.style.display='none';
        }

        MainFunc.k_check_field('.k_form_f_pass', 'pass')
    }

    const handleClick = (event) => {
        const icon = event.target;
        const input = icon.closest('.auth__wrap').querySelector('.auth__input-pass');
        if(toggle) {
            input.type = 'text';
            icon.classList.add('view-pass');
        }  else {
            input.type = 'password';
            icon.classList.remove('view-pass');
        }
        setToggle(!toggle);
    }

    const {
        register,
        getValues,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        mode: "onSubmit",
    });

    $('#password, .k_form_f_pass_2').off('change');
    $('#password, .k_form_f_pass_2').on('change', function (){
        MainFunc.k_check_field('.k_form_f_pass', 'pass')
    });

    $('.k_form_reg_token').off('keyup change');
    $('.k_form_reg_token').on('keyup change', function (){
        $('.k_form_reg_token_error').fadeOut();
        $('.k_form_reg_token').attr('aria-invalid', false);
    });

    const onSubmit = (data) => {
        $('.k_form_reg_mail_error').parent().find('.auth__error').fadeOut();

        let k_error = 0;

        if ( k_error === 0){
            //$('.k_form_reg_token_error').text('');
            $('.k_form_reg_mail_error').text('');


            if ($('.k_form_reg_token').val().indexOf("{") >= 0 && $('.k_form_reg_token').val().indexOf("}") >= 0 || $('.k_form_reg_token').val().indexOf("}") >= 0){
                $('.k_form_reg_token_error').fadeIn();
                $('.k_form_reg_token_error').text('Получен объект, а нужен токен (он обычно внутри объекта)');

                return;
            }

            $.ajax({
                url: site_config.new_api + '/trainers/reg',
                type: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify({trainer_token: $('.k_form_reg_token').val(), email: $('.k_form_reg_mail').val(), password: $('.k_form_reg_pass_1').val(), password_re: $('.k_form_reg_pass_2').val()}),
                success: function(data){
                    window.location.href = '/confirmation2';
                    reset();
                },
                error: function (data){
                    if (data.status === 500){
                        $('.content').html($('#k_is_all').html());
                        return
                    }

                    data = JSON.parse(data.responseText);

                    console.log(data)

                    var show_pass = false;

                    if(data.message === 'Неверный токен'){
                        $('.k_form_reg_token_error').fadeIn();
                        $('.k_form_reg_token_error').text('Неверный токен');

                        show_pass = true;
                    }

                    if(data.message === 'Мы не узнали этот токен. Возможные проблемы: он написан с опечаткой, перепутаны токены для Прода и Стейджа или аккаунт с этим токеном не активирован' || data.message === 'Введенный токен от прода. Нужен токен от стейджа'){
                        $('.k_form_reg_token_error').fadeIn();
                        $('.k_form_reg_token_error').text(data.message);
                        $('.k_form_reg_token').attr('aria-invalid', true);

                        show_pass = true;
                    }

                    if(data.message === 'Аккаунт с таким токеном уже зарегистрирован'){
                        $('.k_form_reg_token_error').fadeIn();
                        $('.k_form_reg_token_error').text('Аккаунт с таким токеном уже создан');

                        show_pass = true;
                    }

                    if(data.message === 'Аккаунт не зарегестрирован'){
                        $('.k_form_reg_token_error').fadeIn();
                        $('.k_form_reg_token_error').text('Такой токен не существует');

                        show_pass = true;
                    }



                    if(data.message === 'Аккаунт с такой почтой уже зарегистрирован'){
                        $('.k_form_reg_mail_error').text('Аккаунт с такой почтой уже создан');
                        $('.k_form_reg_mail_error').fadeIn();

                        show_pass = true;
                    }

                    if(show_pass === false){
                        $('.k_form_reg_pass_1').parent().find('.auth__error').text(data.message);
                        $('.k_form_reg_mail_error').parent().find('.auth__error').fadeIn();
                    }
                }
            });
        }
    };

    setTimeout(function(){
        $('.k_auth_telegram_body').append('<script async src="https://telegram.org/js/telegram-widget.js?21" data-telegram-login="'+kenshi_config.main_telegram_name+'" data-size="large" data-onauth="window.onTelegramAuth(user)" data-request-access="write"></script>');
    }, 1200);

    var url_to_google = 'https://id.vk.com/auth?uuid='+kenshi_config.tech_api_vk_secret_public_hash+'&app_id='+kenshi_config.tech_api_vk_app_id+'&response_type=silent_token&redirect_uri='+KenshiConfig['new_api']+'/technical_routes/callback_vk';

    return (
        <>
            <main className="content">
                <div className="gradient auth_gradient"></div>
                <section className="auth">
                    <div className="auth__content">
                        <h1 className="auth__title register__title">Битва покемонов</h1>
                        <p className="auth__text">Регистрация через соцсеть</p>
                        <div className="auth__socials register__title">
                            <Link to={url_to_google} className="auth__social">
                                <img
                                    src={VK}
                                    className="auth__social-icon"
                                    alt="Иконка социальной сети"
                                />
                            </Link>
                        </div>
                        <div className="auth__mobile auth__mobile-top">
                            <a href="{site_config.new_api}/technical_routes/auth_control" className="auth__back"></a>
                            <p className="auth__title-h2">Регистрация</p>
                        </div>
                        <p className="auth__subtitle register__title_2" dangerouslySetInnerHTML={{__html: site_config['app_kotik_info']}}></p>
                        <form className="auth__form" onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                            <div className="auth__wrap">
                                <input
                                    type="text"
                                    className="auth__input k_form_reg_token"
                                    {...register("token", {
                                        required: "Недействительный токен",
                                        minLength: {
                                            value: 2,
                                            message: "Недействительный токен",
                                        },
                                    })}
                                    aria-invalid={errors.token ? "true" : "false"}
                                    placeholder=' '
                                />
                                <label className="auth__label">Токен</label>
                                <span className="auth__error k_form_reg_token_error">{errors.token?.message}</span>
                            </div>
                            <div className="auth__wrap">
                                <input
                                    type="email"
                                    className="auth__input k_form_reg_mail"
                                    {...register("email", {
                                        required: "Введите почту",
                                        pattern: {
                                            value:
                                                /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                                            message: "Введите почту",
                                        },
                                    })}
                                    aria-invalid={errors.email ? "true" : "false"}
                                    placeholder=' '
                                />
                                <label className="auth__label">Почта</label>
                                <span className="auth__error k_form_reg_mail_error">{errors.email?.message}</span>
                            </div>
                            <div className="df register__df">
                                <div className="auth__wrap">
                                    <input
                                        type='password'
                                        id='password'
                                        className="auth__input auth__input-pass k_form_reg_pass_1 k_form_f_pass_1"
                                        {...register("password", {
                                            required: "Введите пароль",
                                            minLength: {
                                                value: 6,
                                                message: "Минимум 6 символов",
                                            },
                                        })}
                                        aria-invalid={errors.password ? "true" : "false"}
                                        placeholder=' '
                                        onChange={handleChange}
                                    />
                                    <label className="auth__label">Пароль</label>

                                    <span
                                        className="auth__input-icon"
                                        onClick={handleClick}
                                    ></span>
                                    <span className="auth__error">{errors.password?.message}</span>
                                    <p className="auth__subtitle register__title register__title_er"
                                       aria-invalid={errors.password ? "true" : "false"}>
                                        Минимум 6&nbsp;символов, 1&nbsp;заглавная буква и&nbsp;1&nbsp;цифра
                                    </p>
                                </div>
                                <div className="auth__wrap">
                                    <input type='password'
                                           id='confirm-password'
                                           className="auth__input auth__input-pass k_form_reg_pass_2 k_form_f_pass_2"
                                           {...register("pass", {
                                               validate: (match) => {
                                                   const password = getValues("password")
                                                   return match === password || "Пароли не совпадают"
                                               },
                                               required: "Пароли не совпадают",
                                           })}
                                           aria-invalid={errors.pass ? "true" : "false"}
                                           placeholder=' '
                                           onChange={handleChange}
                                    />
                                    <label className="auth__label">Повторите пароль</label>
                                    <span
                                        className="auth__input-icon"
                                        onClick={handleClick}
                                    ></span>
                                    <span className="auth__error">{errors.pass?.message}</span>
                                </div>
                            </div>

                            <button type="submit" className="auth__button">
                                Зарегистрироваться
                            </button>
                            <Link to="/login" className="auth__text auth__registr reg_form_button">
                                Уже зарегистрированы? Войти
                            </Link>
                        </form>
                    </div>
                </section>
            </main>
            <div className="footer_no_line">
                <Footer />
            </div>
        </>
    );
};

export default Registration;
