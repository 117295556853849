import React, {useState} from "react";
import PopupSort from "../PopupSort/PopupSort";
import PopupFilter from "../PopupFilter/PopupFilter";
import Search from "../Filter/Search/Search";
import FilterPokemon from "../Filter/FilterPokemon/FilterPokemon";

import search from "../../images/search.svg";
import "./Pokemon.css";

function Pokemon() {
  // открытие - закрытие попапа
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupOpenFilter, setPopupOpenFilter] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [state, setState] = React.useState(false);
  const [stateInput, setStateInput] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(true);
  const [stateSticky, setStateSticky] = React.useState(false);
  const [stateBtnFilter, setStateBtnFilter] = React.useState(true);

  //Для кнопки "Сбросить" в фильтре
  const [hiddenReset, setHiddenReset] = React.useState(false);

  const body = document.body;

  const counter = window.innerWidth > 992;

  const pokemonClassName = "pokemon k_sticky_header";

  React.useEffect(() => {
    setStateSticky(false);
  }, []);

  window.addEventListener("scroll", function () {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 0 && !counter) {
      setStateSticky(true);
    } else {
      setStateSticky(false);
    }
  });
  
  const options = [
    ["Сначала новые", "asc_date", "selected"],
    ["По убыванию атаки", "desc_price", ""],
    ["По возрастанию атаки", "asc_price", ""],
    ["По алфавиту", "asc", ""],
  ];

  function handleclosePopup(e) {
    const isBtnSort = e.target.closest(".pokemon__sort");
    const isPopup = e.target.closest(".popup-sort_opened");
    const isPopupMobile = e.target.closest(".popup-sort-mobile_opened");
    const isBtnFilter = e.target.closest(".pokemon__filter-btn");
    const isPopupFilter = e.target.closest(".popup-filter_opened");
    const isPopupMobileFilter = e.target.closest(".popup-filter-mobile_opened");

    //const hiddenSort = document.querySelector('.filter-wrapper');

    if (
      !isPopup &&
      !isBtnSort &&
      !isPopupMobile &&
      !isBtnFilter &&
      !isPopupFilter &&
      !isPopupMobileFilter
    ) {
      closePopup();
    }
  }

  document.addEventListener("click", handleclosePopup);

  function closePopup() {
    setPopupOpen(false);
    setPopupOpenFilter(false);
    //setIsOpen(false);
    //counterSelectedDelete();
  }
  function formOpen() {
    setState(true);
    setDisabledButton(false);
  }
  function closeForm() {
    setState(false);
    setDisabledButton(true);
  }

  const [screenWidth, setWidth] = React.useState();

  const findScreenWidth = () => {
    setWidth(window.screen.width);
    if (window.screen.width >= 992) {
      setState(false);
    }
  };

  //Размер экрана
  React.useEffect(() => {
    window.addEventListener("resize", findScreenWidth);
    return () => {
      window.removeEventListener("resize", findScreenWidth);
    };
  }, []);

  //Подсчет выбранных чекбоксов
  const counterSelected = () => {
    setStateBtnFilter(false);
    let out = document.querySelector(".pokemon__filter-counter");
    document.querySelectorAll('.pokemon__container-form input[type="checkbox"], .pokemon__container-form input[type="radio"]').forEach((checkbox) => {
      checkbox.addEventListener("change", () => {
        out.textContent = document.querySelectorAll(".pokemon__container-form input:checked").length;
        if(out.textContent === 0) {
          setStateBtnFilter(true);
          setHiddenReset(false);
        }
      });
    });

    setHiddenReset(true);
  };

  //Сброс выбранных чекбоксов (всех)
  const counterSelectedDelete = () => {
    setStateBtnFilter(true);
    setHiddenReset(false);
    document.querySelectorAll('input[type="checkbox"], input[type="radio"]').forEach((checkbox) => {
      checkbox.checked = false;
      if(checkbox.checked = false) {
        setHiddenReset(false);
      }
    });
  };

  //Выключение прокрутки экрана
  function disabledScroll() {
    //body.classList.add("disable-scroll");
  }
  function enabledScroll() {
    //body.classList.remove("disable-scroll");
  }
  popupOpenFilter || popupOpen ? disabledScroll() : enabledScroll();

  return (
    <>
        <section className={pokemonClassName}>
          <div className="pokemon__content container">
            <div className="pokemon__container">
              <h1 className="pokemon__title">Покемоны</h1>
              <div className='filter-wrapper'>

                {/* Сортировка - начало  */}
                <PopupSort
                  isOpen={popupOpen}
                  onclose={closePopup}
                  setSelectedOption={setSelectedOption}
                  options={options}
                />

                {popupOpen ? (
                  <div
                    type="button"
                    className={
                      popupOpen ? "pokemon__sort active" : "pokemon__sort"
                    }
                    onClick={() => setPopupOpen(false)}
                  >
                    <p className="pokemon__text">
                      {selectedOption || "Сначала новые"}
                    </p>
                    <div type="button" className="pokemon__img-close"></div>
                  </div>
                ) : (
                  <div
                    type="button"
                    className={
                      popupOpen ? "pokemon__sort active" : "pokemon__sort"
                    }
                    onClick={() => setPopupOpen(true)}
                  >
                    <p className="pokemon__text">
                      {selectedOption || "Сначала новые"}
                    </p>
                    <div type="button" className="pokemon__img"></div>
                  </div>
                )}

              {/* Сортировка - конец  */}

              <div className="pokemon__container-form active">
                {/* Поле поиска - начало  */}
                <Search
                  isOpenFilter={popupOpen}
                  setPopupOpenFilter={setPopupOpenFilter}
                />

                {/* Поле поиска - конец  */}

                {/* Фильтр - начало  */}
                <FilterPokemon
                  isOpenFilter={popupOpenFilter}
                  onclose={closePopup}
                  counterSelected={counterSelected}
                  counterSelectedDelete={counterSelectedDelete}
                  setStateBtnFilter={setStateBtnFilter}
                  hiddenReset={hiddenReset}
                  setHiddenReset={setHiddenReset}
                  hidden={false}
                />

                {popupOpenFilter ? (
                  <div
                    type="button"
                    className="pokemon__filter-btn pokemon__filter-btn_active"
                    onClick={closePopup}
                  >
                    <div
                      hidden={stateBtnFilter}
                      className="pokemon__filter-counter"
                    ></div>
                  </div>
                ) : (
                  <div
                    hidden={!disabledButton}
                    type="button"
                    className="pokemon__filter-btn"
                    onClick={() => setPopupOpenFilter(true)}
                  >
                    <div
                      hidden={stateBtnFilter}
                      className="pokemon__filter-counter"
                    ></div>
                  </div>
                )}

                <div
                  hidden={disabledButton}
                  type="button"
                  className="pokemon__filter-close-form"
                  onClick={closeForm}
                ></div>

                {/* Фильтр - конец  */}
              </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
}

export default Pokemon;
