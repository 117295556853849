import React, {useState, useEffect} from "react";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import HistoryBattls from "../../HistoryBattls/HistoryBattls";
import TrenerPokemons from "../TrenerPokemons/TrenerPokemons";
import LayoutInfo from "../../LayoutInfo/LayoutInfo";
import SingleImageElement from "../../PokemonSingle/SingleImageElement";
import TitleSingle from "../../TitleSingle/TitleSingle";

import "./TrainerItem.css";
import "../../HistoryBattls/HistoryBattls.css";
import BackButton from "../../BackButton/BackButton";
import ExportHistory from "./ExportHistory/ExportHistory";

import Cookies from 'js-cookie';
import site_config from "../../kenshi_config/kenshi_config"
import $ from "jquery";
import emptyList from "../../../images/pokemons/no-img.png";
import imageScull from "../../../images/skull.svg";
import imageBall from "../../../images/icons/ball.png";
import imageCup from "../../../images/icons/trophy.png";
import img_settings from "../../../images/icons/settings-icon.svg";
import img_open from "../../../images/log-in.svg";
import img_shop from "../../../images/img_shop.svg";
import img_premium from "../../../images/fire_alt_16.png";
import arrowRight from "../../../images/icons/arrow-r.svg";
import logo from "../../../images/logo-studio.svg";
import number from "../../../images/studio-number.svg";
import NotFound from "../../NotFound/NotFound";

function k_get(name){
    if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
        return decodeURIComponent(name[1]);
}

var shopList = [];
$('body').on('click', '.k_debug_menu_button', function (){

    if ($(this).hasClass('k_show')){
        $('.k_debug_menu_console').removeClass('k_show');
        $(this).removeClass('k_show');
    }else{
        $('.k_debug_menu_console').addClass('k_show');
        $(this).addClass('k_show');
    }
})

$('body').on('click', '.k_debug_menu_c_delete_trainer', function (){
    var is_delete = $(this).attr('data-is-delete');
    if (is_delete === 0 || is_delete === '') {
        $(this).attr('data-is-delete', 1);
        $(this).html('Вы подтверждаете удаление?');
    }

    if (+is_delete === 1){
        $(this).attr('data-is-delete', 2);
        $(this).html('Это действие не обратимо!');
    }

    if (+is_delete === 2){
        $(this).html('Тренер удален');

        var dataz = {}
        dataz['user_id'] = Cookies.get('user_id');
        var headers = {'trainer_token': Cookies.get('session_id')}
        $.ajax({
            url: site_config.main_port_url + site_config.main_port + '/trainers',
            type: 'DELETE',
            dataType: 'json',
            headers: headers,
            contentType: "application/json",
            data: JSON.stringify(dataz),
            success: function (data) {
                setTimeout(function (){
                    Cookies.set('rerender_debug_menu', '1');
                    //window.location.href = '/';
                }, 2000);
            }
        });
    }
})

function time_to_animate(thises, start, end, add, start_color, end_color){
    var shag = 0;
    var shag_val = 0;
    var new_shag = 0
    var direct = false;

    if (start > end){
        shag_val = (start - end);
        shag = shag_val/10;
    }else{
        shag_val = (end - start);
        shag = shag_val/10;
        direct = true;
    }

    thises = thises;
    for (var i= 0; i < 11; i++){
        if (direct == true){
            new_shag = (shag*i) + start
        }else{
            new_shag = start - (shag*i)
        }

        setTimeout(function (thises, new_shag, add){
            if (new_shag === 0 || new_shag === 100){
                add = 0;
            }

            thises.css('background', 'linear-gradient(90deg, '+start_color+' '+new_shag+'%, '+end_color+' '+(new_shag+add)+'%)');
        }, (30*i), thises, new_shag, add)
    }
}

$('body').on('click', '.k_debug_trainer .k_debug_menu_c_kill_pokemon', function (){
    var hp = +$(this).attr('data-hp');
    var hp_add = 15;

    if (!$(this).hasClass('k_alive_pokemon')){
        hp -= 25;

        if (+hp <= 0) {
            $(this).addClass('k_alive_pokemon');
            $(this).html('Нокаут');

            $(this).closest('.k_debug_menu_c_pokemon_active').addClass('k_die')

            var dataz = {}
            dataz['pokemon_id'] = $(this).closest('.k_debug_menu_c_pokemon_active').attr('data-id');
            var thises_rem = $(this);
            $.ajax({
                url: site_config.new_api + '/debug_menu/knockout_pokemon',
                type: 'POST',
                dataType: 'json',
                headers: { 'trainer_token': Cookies.get('session_id') },
                contentType: "application/json",
                data: JSON.stringify(dataz),
                success: function (data) {
                    Cookies.set('rerender_debug_menu', '1');

                    /*$('.pokemons-dropdown__item').each(function (){
                        if (+$(this).attr('data-id') === +dataz['pokemon_id']){
                            $(this).addClass('feature-empty');
                            $(this).css('order', 1);
                            thises_rem.closest('.k_debug_menu_c_pokemon_active').css('order', '1');
                            $(this).find('.title-single__icon').html('<img src="' + imageScull +'" class="title-single__img">');
                            $(this).find('.title-single__icon').attr('data-icon', true);
                            $(this).find('.title-single__icon').attr('data-kill', true);
                        }
                    })*/
                }
            });

        }

        time_to_animate($(this), +$(this).attr('data-hp'), hp, hp_add, '#258f25', 'red');
        $(this).attr('data-hp', hp);
    }

})

$('body').on('click', '.k_debug_menu_c_gen_pokemon', function () {
    var dataz = {}
    dataz['name'] = "generate";
    dataz['photo_id'] = -1;
    dataz['trainer_id'] = window.location.pathname.split('/')[2];
    var thises_rem = this;
    $('.k_main_error_text').remove();
    var thises = this;
    $.ajax({
        url: site_config.new_api + '/debug_menu/generate_pokemon',
        type: 'POST',
        dataType: 'json',
        headers: { 'trainer_token': Cookies.get('session_id') },
        contentType: "application/json",
        data: JSON.stringify(dataz),
        success: function (data) {
            Cookies.set('rerender_debug_menu', '1');

            $.ajax({
                url: site_config.new_api + '/pokemons?pokemon_id=' + data.id,
                type: 'GET',
                headers: { 'trainer_token': Cookies.get('session_id') },
                dataType: 'json',
                success: function (data) {
                    data = data.data[0];

                    if (data.photo_id){
                        data.photo = 'https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/'+(data.photo_id+'').padStart(3, '0')+'.png';
                    }else{
                        data.photo = emptyList;
                    }

                    var element_debug = $($('.k_debug_menu_c_pokemon')[0]).clone();
                    element_debug.find('.k_debug_menu_c_pokemon_photo img').attr('src', data.photo);
                    element_debug.find('.k_debug_menu_c_pokemon_name').html(data.name);
                    element_debug.find('.k_debug_menu_c_pokemon_id').html(data.id);
                    element_debug.attr('data-id', data.id);
                    element_debug.find('.pokemons__link').attr('data-href', "/pokemon/"+data.id);
                    element_debug.find('.k_debug_menu_c_pokemon_pokeball_status').html('Не привязан к покетболу');
                    element_debug.find('.k_debug_menu_c_pokemon_pokeball_change').html('Привязать');
                    element_debug.find('.k_debug_menu_c_pokemon_pokeball_change').attr('data-status', 0);
                    element_debug.removeClass('k_debug_menu_c_pokemon');
                    element_debug.addClass('k_debug_menu_c_pokemon_active');
                    element_debug.css('order', '0');

                    $('.k_debug_menu_c_pokemons').append(element_debug);

                }
            })
        },
        error: function (data){
            data = JSON.parse(data.responseText);

            $(thises).after('<font class="k_main_error_text k_f_error">'+data.message+'</font>')
        }
    });
})

$('body').on('click', '.k_debug_menu_c_delete_pokemon', function (){
    var hp = +$(this).attr('data-hp');
    var hp_add = 15;

    hp -= 25;

    if (+hp <= 0) {
        setTimeout(function (thises){
            var dataz = {}
            dataz['pokemon_id'] = $(thises).closest('.k_debug_menu_c_pokemon_active').attr('data-id');
            var headers = {'trainer_token': Cookies.get('session_id')}
            var thises_rem = thises;
            $.ajax({
                url: site_config.main_port_url + site_config.main_port + '/pokemons',
                type: 'DELETE',
                dataType: 'json',
                headers: headers,
                contentType: "application/json",
                data: JSON.stringify(dataz),
                success: function (data) {
                    Cookies.set('rerender_debug_menu', '1');
                    $('.pokemons-dropdown__item').each(function (){
                        if (+$(this).attr('data-id') === +dataz['pokemon_id']){
                            $('.k_cart_trainer_pokemons_history').html( +$('.k_cart_trainer_pokemons_history').html() - 1);
                            if (+$('.k_cart_trainer_pokemons_history').html() <= 0)
                            {
                                $('.k_cart_trainer_pokemons_history_div').addClass('feature-empty');
                                $('.k_cart_trainer_pokemons_history_div #dropdown').addClass('disabled');
                            }else{
                                $('.k_cart_trainer_pokemons_history_div').removeClass('feature-empty');
                                $('.k_cart_trainer_pokemons_history_div #dropdown').removeClass('disabled');
                            }

                            thises_rem.closest('.k_debug_menu_c_pokemon_active').remove();
                            $(this).remove();
                        }
                    })
                }
            });
        }, 500, this)
    }

    time_to_animate($(this), +$(this).attr('data-hp'), hp, hp_add, 'red', 'black');
    $(this).attr('data-hp', hp);
})

$('body').on('click', '.k_debug_trainer .k_debug_menu_c_pokemon_pokeball_change', function (){
    var dataz = {}
    dataz['pokemon_id'] = $(this).closest('.k_debug_menu_c_pokemon_active').attr('data-id');
    dataz['trainer_id'] = window.location.pathname.split('/')[2];

    var thises = this;
    $('.k_main_error_text').remove();
    if (+$(this).attr('data-status') === 1){
        $.ajax({
            url: site_config.new_api + '/debug_menu/delete_pokeball',
            type: 'POST',
            dataType: 'json',
            headers: { 'trainer_token': Cookies.get('session_id') },
            contentType: "application/json",
            data: JSON.stringify(dataz),
            success: function (data) {
                Cookies.set('rerender_debug_menu', '1');

                $(thises).parent().find('.k_debug_menu_c_pokemon_pokeball_status').html('Не привязан к покетболу');
                $(thises).html('Привязать');
                $(thises).attr('data-status', 0);

                $('.pokemons-dropdown__item').each(function (){
                    if (+$(this).attr('data-id') === +dataz['pokemon_id']){
                        $(this).find('.title-single__icon').html('<img src="'+imageBall+'" class="title-single__img">');
                        $(this).find('.title-single__icon').attr('data-icon', false);
                        $(this).find('.title-single__icon').attr('data-kill', false);
                    }
                })
            }
        });
    }else{
        $.ajax({
            url: site_config.new_api + '/debug_menu/add_pokeball',
            type: 'POST',
            dataType: 'json',
            headers: { 'trainer_token': Cookies.get('session_id') },
            contentType: "application/json",
            data: JSON.stringify(dataz),
            success: function (data) {
                Cookies.set('rerender_debug_menu', '1');

                $(thises).parent().find('.k_debug_menu_c_pokemon_pokeball_status').html('Привязан к покетболу');
                $(thises).html('Отвязать');
                $(thises).attr('data-status', 1)

                $('.pokemons-dropdown__item').each(function (){
                    if (+$(this).attr('data-id') === +dataz['pokemon_id']){
                        $(this).find('.title-single__icon').html('<img src="'+imageBall+'" class="title-single__img">');
                        $(this).find('.title-single__icon').attr('data-icon', true);
                        $(this).find('.title-single__icon').attr('data-kill', false);
                    }
                })
            },
            error: function (data){
                data = JSON.parse(data.responseText);

                $(thises).parent().parent().after('<font class="k_main_error_text k_f_error">'+data.message+'</font>')
            }
        });
    }
})

$('body').on('click', '.k_debug_photo_trainer', function (){
    var dataz = {};
    dataz['trainer_id'] = window.location.pathname.split('/')[2];
    dataz['avatar_id'] = $(this).attr('data-id');
    var thises_avatar = this;
    $.ajax({
        url: site_config.new_api + '/debug_menu/edit_trainer_avatar',
        type: 'PATCH',
        dataType: 'json',
        headers: {'trainer_token': Cookies.get('session_id')},
        contentType: "application/json",
        data: JSON.stringify(dataz),
        success: function (data) {
            Cookies.set('rerender_debug_menu', '1');

            $('.k_debug_photo_trainer').removeClass('feature-empty');
            //$('img.trainer-img').attr('src', $(thises_avatar).attr('data-img'));
            $(thises_avatar).addClass('feature-empty');
        }
    });
})

var s_int = '';
$('body').on('keyup', '.k_debug_trainer .k_debug_menu_c_input', function () {
    clearTimeout(s_int)
    var thises = $(this);
    s_int = setTimeout(function (thises){
        if (thises.val() !== '') {
            $('.k_main_error_text').remove();
            var dataz = {}
            var headers = {'trainer_token': Cookies.get('session_id')}
            dataz['trainer_id'] = window.location.pathname.split('/')[2];
            dataz[thises.attr('data-name')] = thises.val();
            $.ajax({
                url: site_config.new_api + '/debug_menu/update_trainer',
                type: 'PATCH',
                dataType: 'json',
                headers: headers,
                contentType: "application/json",
                data: JSON.stringify(dataz),
                success: function (data) {
                    if (thises.attr('data-name') === 'level') {
                        $('.k_cart_trainer_level').html(thises.val());
                        $('.k_cart_trainer_level + ul > li').attr('data-active', thises.val());

                        if (+thises.val() === 5) {
                            $('.trainer-item__content > .title-single > .title-single__subtitle').html('Чемпион');
                            var k_trainer_block = $('.trainer-item__content > .title-single > .title-single__box');

                            k_trainer_block.find('.k_cart_trainer_cup').html('<img src="' + imageCup + '" class="title-single__img">');
                            k_trainer_block.find('.k_cart_trainer_cup').attr('data-icon', true);
                            k_trainer_block.find('.k_cart_trainer_cup').attr('data-kill', false);
                            k_trainer_block.find('.k_cart_trainer_cup').fadeIn();
                        } else {
                            $('.trainer-item__content > .title-single > .title-single__subtitle').html('Тренер');
                            var k_trainer_block = $('.trainer-item__content > .title-single > .title-single__box');

                            k_trainer_block.find('.k_cart_trainer_cup').html('<img src="' + imageCup + '" class="title-single__img">');
                            k_trainer_block.find('.k_cart_trainer_cup').attr('data-icon', false);
                            k_trainer_block.find('.k_cart_trainer_cup').attr('data-kill', false);
                            k_trainer_block.find('.k_cart_trainer_cup').fadeOut();
                        }

                        Cookies.set('rerender_debug_menu_level', thises.val());
                    }


                    $('.k_cart_trainer_' + thises.attr('data-name')).html(thises.val());
                    Cookies.set('rerender_debug_menu_name', '.k_cart_trainer_' + thises.attr('data-name') + '::' + thises.val());
                },
                error: function (data) {
                    data = JSON.parse(data.responseText);
                    $(thises).after('<font class="k_main_error_text k_f_error">' + data.message + '</font>')
                }
            });
        }

        clearTimeout(s_int);
    }, 1200, thises)

})

$('body').on('click', '.k_button_get_history', function(){
    if ($(this).hasClass('go')){
        $('.export__descr').html('Подготовка файла: 1 час');
        $('.k_button_get_history').removeClass('go').find('span').html('Заказать');
        window.open(site_config.new_api + '/trainers/download_history?trainer_token='+Cookies.get('session_id'), '_blank');
    }else{
        $.ajax({
            url: site_config.tech_api + '/trainers/kafka',
            type: 'POST',
            dataType: 'json',
            headers: { 'trainer_token': Cookies.get('session_id') },
            data: {trainer_token: Cookies.get('session_id')},
            success: function(data){
                $('.export__descr').html('Идет подготовка файла...');
                $('.k_button_get_history').addClass('disable').find('span').html('Скачать');
            }, error: function (){
                $('.k_500').fadeIn();
                $('.k_trassing_template_all').css('display', 'none');
                $('.k_hide_block').css('display', 'none');
            }
        });
    }
});

Cookies.set('K_global_gets_trainer', 0, { expires: 365 });

var achievements_animate_prev = false;
var timer_achievements = false;
$('body').on('mouseenter', '.achievements_icon', function (){

    var ach_title = $('.achievements_popup_content_title');
    var ach_txt = $('.achievements_popup_content_txt');
    if ($(this).attr('data-slug') === 'beginning'){
        ach_title.html('Начало большого пути');
        ach_txt.html('Первая победа');
    }
    if ($(this).attr('data-slug') === 'out_of_battles'){
        ach_title.html('Упёртый как бык');
        ach_txt.html('Достиг дневного лимита боёв');
    }
    if ($(this).attr('data-slug') === 'self_knockout'){
        ach_title.html('Будет<br>что вспомнить');
        ach_txt.html('Отправил своего покемона в нокаут');
    }
    if ($(this).attr('data-slug') === 'max_level'){
        ach_title.html('Чемпион');
        ach_txt.html('Достиг 5 уровня');
    }
    if ($(this).attr('data-slug') === 'one_vs_seven'){
        ach_title.html('Один на миллион');
        ach_txt.html('Атака 1 победила атаку 7');
    }
    if ($(this).attr('data-slug') === 'five_battles'){
        ach_title.html('По одному за раз');
        ach_txt.html('5 побед подряд одним покемоном');
    }
    if ($(this).attr('data-slug') === 'five_defends'){
        ach_title.html('Крепкий орешек');
        ach_txt.html('Покемон выдержал 3 нападения подряд');
    }


   var i_icon = 0;
   var this_icon = this;
   var i_conf_find = 1;
   $('.achievements_icon').each(function (){
       if (i_conf_find === 1) {
           if (this === this_icon) {
               i_conf_find = 0;
           } else {
               i_icon += 1;
           }
       }
   })

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        var add_pixels = 32;
    }else{
        var add_pixels = 40;
    }

   if (i_icon !== 0){
       add_pixels += 4
   }

   achievements_check_offset(add_pixels, i_icon);

   clearTimeout(timer_achievements);
   if (achievements_animate_prev === false){
       setTimeout(function (){
           $('.achievements_popup').css('opacity', '1');
           $('.achievements_popup').css('z-index', '99999');
           $('.achievements_popup').css('transition', '0.6s');
       }, 200)
       achievements_animate_prev = true;
   }
});

var add_pixels_top = 0;
function achievements_check_offset(add_pixels, i_icon){
    var left_spaccing = ((add_pixels * i_icon) - ($('.achievements_popup').width()/2-(add_pixels-4)/2));

    $('.achievements_popup_top').css('left', ($('.achievements_popup').width()/2-26) + 'px');

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        if (left_spaccing < -10) {
            left_spaccing = -10;

            if (i_icon !== 0) {
                add_pixels_top = 4
            }

            $('.achievements_popup_top').css('left', ((((32 + add_pixels_top) * (i_icon + 1)) - 26) - 10) + 'px');
        }
    }

    $('.achievements_popup').css('left', left_spaccing + 'px');
}

$('body').on('mouseenter', '.achievements_popup', function (e){
    clearTimeout(timer_achievements);
})

$('body').on('mouseleave', '.achievements_icon, .achievements_popup', function (e){
    if ($('.achievements_popup:hover')[0]) {
        return false;
    }

    $('.achievements_icon:hover').each(function (){
        return false;
    })

    achievements_animate_prev = false;

    timer_achievements = setTimeout(function (){
        $('.achievements_popup').css('opacity', '0');
        $('.achievements_popup').css('z-index', '-1');

        setTimeout(function (){
            $('.achievements_popup').css('transition', '0s');
        }, 200)
    }, 200)
})

const TrainerItem = () => {
    var list_tt = false;
    const [trainer_name, setTrainer_name] = useState("");
    const [trainer_city, setTrainer_city] = useState("");
    const [trainer_level, setTrainer_level] = useState("");

    useEffect(() => {
        $('.single-gradient').css('left', (($('.trainer-img').offset().left-$('.single-gradient').width()/2)+($('.trainer-img').width()/2) - 50)+'px');
    }, [])

    var achievements = false;
    if (Cookies.get('K_global_gets_trainer') == 0){
        var pokemons = [];
        var battles = [];
        var rate = -1;
        var e404 = false;
        var trainer_id = window.location.pathname.split('/');
        $.ajax({
            url: site_config.new_api + '/technical_routes/single_trainer_data',
            type: 'POST',
            headers: { 'trainer_token': Cookies.get('session_id') },
            data: JSON.stringify({ 'trainer_id': trainer_id[trainer_id.length - 1] }),
            contentType: "application/json",
            async: false,
            success: function (data) {
                try {
                    pokemons = data['pokemons'];
                } catch (e) {
                    //
                }
                try {
                    battles = data['battles'];
                } catch (e) {
                    //
                }

                achievements = data['achievements'];
                rate = data['rate'];

                Cookies.set('K_global_gets_trainer', 1, {expires: 365});
            },
            error: function (){
                e404 = true;
            }
        })

        $.ajax({
            url: site_config.new_api + '/trainers?trainer_id='+trainer_id[trainer_id.length - 1]+'&page=0',
            type: 'GET',
            headers: { 'trainer_token': Cookies.get('session_id') },
            dataType: 'json',
            success: function (data_one) {
                if (e404 === true){
                    return;
                }

                if (rate !== -1 && rate > 0 && rate <= 30){
                    $('.trainer-item__rate').css('display', 'flex');
                    $('.k_cart_trainer_rate').html(rate);

                    if (rate === 1){
                        $('.k_cart_trainer_rate_img').addClass('k_cart_trainer_rate_img_1');
                    } else if (rate === 2){
                        $('.k_cart_trainer_rate_img').addClass('k_cart_trainer_rate_img_2');
                    } else if (rate === 3){
                        $('.k_cart_trainer_rate_img').addClass('k_cart_trainer_rate_img_3');
                    } else if (rate > 3 && rate <= 10){
                        $('.k_cart_trainer_rate_img').addClass('k_cart_trainer_rate_img_10');
                    } else if (rate > 10 && rate <= 30){
                        $('.k_cart_trainer_rate_img').addClass('k_cart_trainer_rate_img_30');
                    }
                }

                for (var iz=0; iz < achievements.length; iz++){
                    $('.achievements_icon').each(function (){
                        if ($(this).attr('data-slug') === achievements[iz].slug){
                            $(this).addClass('active');
                        }
                    })
                }

                try {
                    var data = data_one.data[0];
                } catch (err){
                    var data = data_one.data;
                }


                if (!data.id){
                    window.location.href = '/500';
                }

                setTrainer_name(data.trainer_name);
                setTrainer_city(data.city);
                setTrainer_level(data.level);

                $('.k_cart_trainer_img img').attr('src', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data.avatar_id).padStart(2, '0')+'.png');
                $('.k_cart_trainer_name').html(data.trainer_name);
                $('.k_cart_trainer_id p').html(data.id);

                $('.k_cart_trainer_city').html(data.city);
                $('.k_cart_trainer_level').html(data.level);
                $('.k_cart_trainer_pokemons').html(data.pokemons_in_pokeballs.length);
                $('.k_cart_trainer_pokemons_history').html(data.pokemons.length);
                if ($(window).width() > 768) {
                    if (data.pokemons.length <= 3) {
                        $('.k_cart_trainer_pokemons_history_div_drop').css('height', 'auto');
                    } else {
                        $('.k_cart_trainer_pokemons_history_div_drop').css('height', '400px');
                    }
                }

                if (Cookies.get('user_id') === data.id){
                    $('.k_cart_trainer_export').css('visibility', 'visible');
                    $('.k_trainer_exit').css('visibility', 'visible');
                    $('.k_profile_trainer').css('display', 'flex');
                    if (Cookies.get('is_debug') === '1'){
                        $('.k_debug_menu').css('visibility', 'visible');
                        Cookies.set('allow_debug_menu', '1');
                    }else{
                        $('.k_debug_menu').remove();
                        Cookies.set('allow_debug_menu', '0');
                    }
                }else{
                    $('.k_cart_trainer_export').remove();
                    $('.k_trainer_exit').remove();
                    $('.k_debug_menu').remove();
                    $('.k_profile_trainer').remove();
                }

                if (data.get_history_battle !== '1' && data.get_history_battle !== '0'){
                    $('.k_cart_trainer_export').find('.export__descr').html('Идет подготовка файла...');
                    $('.k_cart_trainer_export .export__button').addClass('disable').find('span').html('Скачать');
                }

                if (data.get_history_battle === '1'){
                    $('.k_cart_trainer_export').find('.export__descr').html('Файл можно скачать');
                    $('.k_cart_trainer_export .export__button').addClass('go').find('span').html('Скачать');
                }

                if (data.level >= 5){
                    $('.k_cart_trainer_cup').fadeIn();
                    $('.single-trainer .title-single__subtitle').html('Чемпион');
                }

                if (data.is_premium){
                    $('.k_cart_trainer_premium').fadeIn();
                }

                if (data.pokemons.length === 0){
                    $('.k_cart_trainer_pokemons_history_div').addClass('feature-empty');
                    $('.k_cart_trainer_pokemons_history_div #dropdown').addClass('disabled');
                }else{
                    $('.k_cart_trainer_pokemons_history_div').removeClass('feature-empty');
                    $('.k_cart_trainer_pokemons_history_div #dropdown').removeClass('disabled');
                }

                $('.k_cart_trainer_level + ul li').each(function (){
                    $(this).attr('data-active', data.level);
                });

                $('.k_cart_trainer_pokemons + ul li').each(function (){
                    $(this).attr('data-active', data.pokemons_in_pokeballs.length);
                });

                var global_battles = 0;
                var last_elements = [];
                var global_battles_date = [];
                var i_battle = 0;

                $('.k_cart_trainer_pokemons_history_div_drop .pokemons-dropdown__item_to_remove').remove();
                $('.k_debug_menu_c_pokemons .k_debug_menu_c_pokemon_active').remove();

                var max_id_pok = 0;
                for (var i3 = 0; i3 < pokemons.length; i3++){
                    if (max_id_pok < pokemons[i3].id){
                        max_id_pok = pokemons[i3].id;
                    }
                }

                for (var i2 = 0; i2 < pokemons.length; i2++){
                    var last_element = pokemons[i2];

                    var element = $('.k_cart_trainer_pokemons_history_div_drop .pokemons-dropdown__item_none').clone();
                    element.attr('data-id', last_element.id);

                    if (last_element.photo_id){
                        last_element.photo = 'https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/'+(last_element.photo_id+'').padStart(3, '0')+'.png';
                    }else{
                        last_element.photo = emptyList;
                    }
                    element.find('.pokemons-dropdown__img').attr('src', last_element.photo);
                    element.find('.pokemons-dropdown__title').text(last_element.name);
                    element.find('.copy__text').text(last_element.id);
                    //element.find('.pokemons__link').attr('data-href', "/pokemon/"+last_element.id);
                    element.find('.pokemons__link_h').attr('data-href-pokemon', last_element.id);

                    var element_debug = $($('.k_debug_menu_c_pokemon')[0]).clone();
                    element_debug.find('.k_debug_menu_c_pokemon_photo img').attr('src', last_element.photo);
                    element_debug.find('.k_debug_menu_c_pokemon_name').html(last_element.name);
                    element_debug.find('.k_debug_menu_c_pokemon_id').html(last_element.id);
                    element_debug.attr('data-id', last_element.id);

                    element_debug.find('.pokemons__link_h').attr('data-href-pokemon', last_element.id);
                    //element_debug.find('.pokemons__link').attr('data-href', "/pokemon/"+last_element.id);

                    if (last_element.status === 1 && last_element.in_pokeball === 1){
                        element.find('.k_title_icon_status_pok').attr('data-icon', 'true');
                        element.find('.k_title_icon_status_pok').attr('data-kill', 'false');

                        element_debug.find('.k_debug_menu_c_pokemon_pokeball_status').html('Привязан к покетболу');
                        element_debug.find('.k_debug_menu_c_pokemon_pokeball_change').html('Отвязать');
                        element_debug.find('.k_debug_menu_c_pokemon_pokeball_change').attr('data-status', 1);
                    }

                    if (last_element.status === 1 && last_element.in_pokeball === 0){
                        element.find('.k_title_icon_status_pok').attr('data-icon', 'false');
                        element.find('.k_title_icon_status_pok').attr('data-kill', 'false');
                        element_debug.find('.k_debug_menu_c_pokemon_pokeball_status').html('Не привязан к покетболу');
                        element_debug.find('.k_debug_menu_c_pokemon_pokeball_change').html('Привязать');
                        element_debug.find('.k_debug_menu_c_pokemon_pokeball_change').attr('data-status', 0);
                    }

                    if (last_element.status === 0){
                        element.css('order', '9999'+(max_id_pok - last_element.id));
                        element_debug.css('order', '9999'+(max_id_pok - last_element.id));

                        element_debug.addClass('k_die');
                        element_debug.find('.k_debug_menu_c_kill_pokemon').attr('data-hp', 0);
                        element_debug.find('.k_debug_menu_c_kill_pokemon').html('Нокаут');
                        element_debug.find('.k_debug_menu_c_kill_pokemon').addClass('k_alive_pokemon');
                        element_debug.find('.k_debug_menu_c_kill_pokemon').css('background', 'linear-gradient(90deg, #258f25 0%, red 0%)');
                        element.find('.k_title_icon_status_pok').attr('data-icon', 'true');
                        element.find('.k_title_icon_status_pok').attr('data-kill', 'true');
                        element.find('.k_title_icon_status_pok img').attr('src', imageScull);
                        element.addClass('feature-empty');
                    }else{
                        element.find('.parent__mainblock_card_pokemon_id').addClass('copy_id');
                        element.find('.k_trassing_r_copy').fadeIn();
                        element.css('order', '1'+(max_id_pok - last_element.id));
                        element_debug.css('order', '1'+(max_id_pok - last_element.id));
                    }

                    element_debug.removeClass('k_debug_menu_c_pokemon');
                    element_debug.addClass('k_debug_menu_c_pokemon_active')
                    $('.k_debug_menu_c_pokemons').append(element_debug);

                    if (!last_element.photo_id){
                        element.find('.parent__mainblock_card_img img').attr('data-src', emptyList);
                    }

                    var attack = Math.round(last_element.attack);

                    element.find('.level').attr('data-level', attack);
                    element.find('.level__name').attr('data-level', attack);
                    element.find('.level__count').attr('data-level', attack).html(attack);

                    element.fadeIn();
                    element.removeClass('pokemons-dropdown__item_none');
                    element.addClass('pokemons-dropdown__item_to_remove');
                    $('.k_cart_trainer_pokemons_history_div_drop').append(element);

                    last_elements[last_element.id] = last_element;
                }

                var battle_enemy = [];
                for (var i = 0; i < battles.length; i++){
                    var battle = battles[i];

                    if (battle.my_id === battle.attack_pokemon.id) {
                        last_element = battle.attack_pokemon;
                        battle_enemy = battle.defend_pokemon;
                    }else{
                        last_element = battle.defend_pokemon;
                        battle_enemy = battle.attack_pokemon;
                    }

                    var element = $('.k_trassing_block_battles_none').clone();

                    if (last_element.photo_id){
                        last_element.photo = 'https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/'+(last_element.photo_id+'').padStart(3, '0')+'.png';
                    }else{
                        last_element.photo = emptyList;
                    }
                    element.find('.k_trassing_r_my_pokemon img').attr('src', last_element.photo);
                    element.find('.k_trassing_r_my_pokemon').attr('data-id', last_element.id);
                    element.find('.k_trassing_r_my_pokemon .history-card__title').html(last_element.name);

                    element.find('.k_trassing_r_my_pokemon .level').attr('data-level', Math.round(last_element.attack));
                    element.find('.k_trassing_r_my_pokemon .level__name').attr('data-level', Math.round(last_element.attack));
                    element.find('.k_trassing_r_my_pokemon .level__count').attr('data-level', Math.round(last_element.attack)).html(Math.round(last_element.attack));

                    element.find('.k_trassing_r_other_pokemon .level').attr('data-level', Math.round(battle_enemy.attack));
                    element.find('.k_trassing_r_other_pokemon .level__name').attr('data-level', Math.round(battle_enemy.attack));
                    element.find('.k_trassing_r_other_pokemon .level__count').attr('data-level', Math.round(battle_enemy.attack)).html(Math.round(battle_enemy.attack));

                    element.find('.k_trassing_r_my_pokemon .pok_id').text(last_element.id);
                    element.find('.k_trassing_r_my_pokemon .pokemons__link_h').attr('data-href-pokemon', last_element.id);

                    var date = new Date(battle.date);
                    element.find('.date').text(date.getDate().toString().padStart(2, '0')+'.'+(date.getMonth() + 1).toString().padStart(2, '0')+'.'+date.getFullYear().toString().substr(-2));

                    element.addClass('k_battle_'+i_battle);
                    global_battles_date[i_battle] = [];
                    global_battles_date[i_battle]['ids'] = i_battle;

                    var date_int = Number(date);
                    global_battles_date[i_battle]['dates'] = date_int;
                    i_battle++;

                    if (battle.my_id !== battle.winner_id) {
                        var k_status = 0
                    }else {
                        var k_status = 1
                    }

                    if (k_status === 1){
                        element.find('.history-item__bottom').addClass('victory');
                        element.find('.history-item__bottom').removeClass('defeat');
                        element.find('.history-item__bottom span').html('Победа '+last_element.name+'!');
                        element.find('.history-item__bottom img').remove();

                        element.find('.k_trassing_r_other_pokemon').addClass('defeat');
                    }else{
                        element.find('.history-item__bottom').removeClass('victory');
                        element.find('.history-item__bottom').addClass('defeat');
                        element.find('.history-item__bottom span').html('Поражение '+last_element.name+'!');

                        element.find('.k_trassing_r_my_pokemon').addClass('defeat');
                    }

                    if (battle.attack_pokemon.id === last_element.id){
                        element.find('.k_trassing_r_other_pokemon').addClass('defeat_k');
                        element.find('.k_trassing_r_my_pokemon').addClass('victory');
                    }else{
                        element.find('.k_trassing_r_my_pokemon').addClass('defeat_k');
                        element.find('.k_trassing_r_other_pokemon').addClass('victory');
                    }

                    element.removeClass('k_trassing_block_battles_none');
                    element.fadeIn();

                    var enemy_pok = element.find('.k_trassing_r_other_pokemon');
                    if (battle_enemy.photo_id){
                        battle_enemy.photo = 'https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/'+(battle_enemy.photo_id+'').padStart(3, '0')+'.png';
                    }else{
                        battle_enemy.photo = emptyList;
                    }

                    enemy_pok.find('img').attr('src', battle_enemy.photo);
                    enemy_pok.find('.history-card__title').text(battle_enemy.name);
                    enemy_pok.find('.pok_id').text(battle_enemy.id);
                    enemy_pok.find('.pokemons__link_h').attr('data-href-pokemon', battle_enemy.id);
                    enemy_pok.attr('data-id', battle_enemy.id);

                    $('.k_trassing_repeater_battles').append(element);
                    var element = '';
                    global_battles++;
                }
                $('.history-info_count').html(global_battles);
                if ($(window).width() > 768) {
                    if (global_battles <= 3) {
                        $('.history__list').css('height', 'auto');
                    } else {
                        $('.history__list').css('height', '400px');
                    }
                }

                if (global_battles === 0){
                    $('.trainer-item__history').addClass('feature-empty');
                }else{
                    $('.trainer-item__history').removeClass('feature-empty');
                }

                global_battles_date.sort(function(a, b) {
                    return parseFloat(a.dates) - parseFloat(b.dates);
                });

                for (i = 0; i < global_battles_date.length; i++){
                    $('.k_battle_'+global_battles_date[i].ids).css('order', '-'+''+i);
                }

                if (global_battles === 0){
                    $('.trainer-item__history').addClass('feature-empty');
                }else{
                    $('.trainer-item__history').removeClass('feature-empty');
                }

            }, error: function (){
                window.location.href = '/500';
            }
        });
        Cookies.set('K_global_gets_trainer', 1, { expires: 365 });
    }

    const [data, setData] = React.useState("");
    const [select, setSelect] = React.useState(false);
    const [select2, setSelect2] = React.useState(false);

    if (e404){
        return (
            <NotFound status="404" is_over="1"/>
        );
    }

    if (k_get('debug_trainer_active') === "1"){

        if (list_tt === false && !$('.k_debug_photo_trainer_exists')[0]) {
            $.ajax({
                url: site_config.new_api + '/debug_menu/get_avatars',
                type: 'GET',
                dataType: 'json',
                async: false,
                success: function (data) {
                    data = data.data;

                    if (!shopList[0]) {
                        for (var i = 0; i < data.length; i++) {
                            var class_this = 'shop__item';
                            if ('https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data[i].id).padStart(2, '0')+'.png' === Cookies.get('user_image')) {
                                class_this += ' feature-empty';
                            }


                            shopList.push({
                                img: 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data[i].id).padStart(2, '0')+'.png',
                                name: "Аватар тренера № " + (i + 1),
                                price: data[i].price + ' ₽',
                                link: data[i].id,
                                class: class_this
                            });
                        }
                    }
                }
            });

            if (!$('.k_debug_photo_trainer_exists')[0]) {
                for (var i = 0; i < shopList.length; i++) {
                    var shop_element = $('.k_debug_photo_trainer_example').clone();
                    shop_element.removeClass('k_debug_photo_trainer_example');
                    shop_element.addClass('k_debug_photo_trainer_exists');
                    shop_element.find('img').attr('src', shopList[i].img);
                    shop_element.attr('data-id', (+shopList[i].link));
                    shop_element.attr('data-img', (shopList[i].img));
                    shop_element.addClass(shopList[i].class);
                    $('.k_debug_photo_trainers').append(shop_element);
                }
            }
            list_tt = true;
        }

        return (
            <>
                <main
                    className={
                        data != "" ? "content his-open container" : "content container"
                    }
                >
                    <div className='k_debug_menu'>
                        <div className="content__box content__box-desc" style={{display:'none'}}>
                            <LayoutInfo
                                id=" "
                                city=" "
                                level=" "
                                stage=""
                                attack=""
                                countsPokemons=" "
                            />
                            <ExportHistory status="Подготовка файла: 1 час"/>
                        </div>

                        <div className='k_debug_menu_button'>
                            <div className='k_debug_b_icon'> > </div>
                        </div>
                        <div className='k_debug_menu_console k_debug_trainer'>
                            <div className='k_debug_menu_c_title'>Редактировать тренера</div>

                            <div className='k_debug_menu_c_flex'>
                                <label>уровень</label>
                                <input type='number' data-name='level' className='k_debug_menu_c_input k_debug_menu_c_trainer_level' defaultValue={trainer_level} />
                            </div>

                            <div className='k_debug_menu_c_flex'>
                                <label>Фото</label>
                                <div className='k_debug_photo_trainers'>
                                    <div className='k_debug_photo_trainer k_debug_photo_trainer_example' data-id=''>
                                        <img />
                                    </div>
                                </div>
                            </div>

                            <div className='k_debug_menu_c_flex'>
                                <label>Имя</label>
                                <input type='text' data-name='name' className='k_debug_menu_c_input k_debug_menu_c_trainer_name' defaultValue={trainer_name} />
                            </div>

                            <div className='k_debug_menu_c_flex'>
                                <label>Город</label>
                                <input type='text' data-name='city' className='k_debug_menu_c_input k_debug_menu_c_trainer_city' defaultValue={trainer_city} />
                            </div>

                            <div className='k_debug_menu_c_b k_debug_menu_c_gen_pokemon' data-is-delete=''>Сгенерировать покемона</div>

                            <div className='k_debug_menu_c_pokemons'>
                                <div className='k_debug_menu_c_pokemon'>
                                    <div className='k_debug_menu_c_pokemon_top'>
                                        <div className='k_debug_menu_c_pokemon_photo'><img src=''/></div>
                                        <div className='k_debug_menu_c_pokemon_info'>
                                            <div className='k_debug_menu_c_pokemon_name'>Pikachu</div>
                                            <div className='k_debug_menu_c_pokemon_id'>666</div>
                                        </div>
                                        <div className='k_debug_menu_c_pokemon_pokeball'>
                                            <div className='k_debug_menu_c_pokemon_pokeball_status'>Не привязан к покетболу</div>
                                            <div className='k_debug_menu_c_b k_debug_menu_c_pokemon_pokeball_change'>Привязать</div>
                                        </div>
                                    </div>
                                    <div className='k_debug_menu_c_pokemon_actions'>
                                        <div className='k_debug_menu_c_b k_debug_menu_c_kill_pokemon' data-hp='100'>В нокаут</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }else {
        setInterval(function (){
            if (Cookies.get('rerender_debug_menu') === '1'){
                window.location.reload();
                Cookies.set('rerender_debug_menu', '0');
            }
            if (Cookies.get('rerender_debug_menu_level') && Cookies.get('rerender_debug_menu_level') !== 'false'){
                var rerender_debug_menu_level = Cookies.get('rerender_debug_menu_level');

                $('.k_cart_trainer_level').html(rerender_debug_menu_level);
                $('.k_cart_trainer_level + ul > li').attr('data-active', rerender_debug_menu_level);

                if (+rerender_debug_menu_level === 5) {
                    $('.trainer-item__content > .title-single > .title-single__subtitle').html('Чемпион');
                    var k_trainer_block = $('.trainer-item__content > .title-single > .title-single__box');

                    k_trainer_block.find('.k_cart_trainer_cup').html('<img src="' + imageCup + '" class="title-single__img">');
                    k_trainer_block.find('.k_cart_trainer_cup').attr('data-icon', true);
                    k_trainer_block.find('.k_cart_trainer_cup').attr('data-kill', false);
                    k_trainer_block.find('.k_cart_trainer_cup').fadeIn();
                } else {
                    $('.trainer-item__content > .title-single > .title-single__subtitle').html('Тренер');
                    var k_trainer_block = $('.trainer-item__content > .title-single > .title-single__box');

                    k_trainer_block.find('.k_cart_trainer_cup').html('<img src="' + imageCup + '" class="title-single__img">');
                    k_trainer_block.find('.k_cart_trainer_cup').attr('data-icon', false);
                    k_trainer_block.find('.k_cart_trainer_cup').attr('data-kill', false);
                    k_trainer_block.find('.k_cart_trainer_cup').fadeOut();
                }

                Cookies.set('rerender_debug_menu_level', 'false');
            }
            if (Cookies.get('rerender_debug_menu_name') && Cookies.get('rerender_debug_menu_name') !== 'false'){
                var name_and_class = Cookies.get('rerender_debug_menu_name').split('::');
                $(name_and_class[0]).html(name_and_class[1]);
                Cookies.set('rerender_debug_menu_name', 'false');
            }
        }, 1000);

        return (
            <>
                <Header/>
                <main
                    className={
                        data != "" ? "content his-open container" : "content container"
                    }
                >
                    <section className="trainer-item single-trainer">


                        <BackButton link="javascript:history.back()"/>
                        <SingleImageElement isDead/>
                        <div className="trainer-item__content">
                            <TitleSingle
                                subtitle="Тренер"
                                title=" "
                                iconBall={false}
                                iconCoin={false}
                                iconScull={false}
                                iconCup={true}
                                kill={false}
                                data={true}
                            />

                            <div className="content__inner">
                                <div className="content__box content__box-desc">
                                    <LayoutInfo
                                        id=" "
                                        city=" "
                                        level=" "
                                        stage=""
                                        attack=""
                                        countsPokemons=" "
                                    />
                                    <ExportHistory status="Подготовка файла: 1 час"/>
                                </div>
                                <div className="content__box content__box-mobile">
                                    <TrenerPokemons select={select} setSelect={setSelect}/>
                                    <HistoryBattls/>
                                    <ExportHistory status="Подготовка файла: 1 час"/>
                                    <a href="/premium" className="history-top k_profile_trainer k_premium_button_fix">
                                        <img src={img_premium} width="24" height="24" alt="Премиум"/>
                                        <div className="history-info">
                                            Pokemon Premium <div className="history-info-duration" style={{'display': 'none'}}><div className="history-info-duration-num"></div> <div className="history-info-duration-day"></div></div>
                                        </div>
                                        <button id="dropdown" className={"btn-down btn-reset"}>
                                            <img src={arrowRight} width="26" height="26" alt="Премиум"/>
                                        </button>
                                    </a>
                                    <a href="/shop" className="history-top k_profile_trainer k_premium_button_fix">
                                        <img src={img_shop} width="24" height="24" alt="Смена аватара"/>
                                        <div className="history-info">
                                            Смена аватара
                                        </div>
                                        <button id="dropdown" className={"btn-down btn-reset"}>
                                            <img src={arrowRight} width="26" height="26" alt="Смена аватара"/>
                                        </button>
                                    </a>
                                    <a href="/profile" className="history-top k_profile_trainer">
                                        <img src={img_open} width="24" height="24" alt="Настройки входа"/>
                                        <div className="history-info">
                                            Настройки входа
                                        </div>
                                        <button id="dropdown" className={"btn-down btn-reset"}>
                                            <img src={arrowRight} width="26" height="26" alt="Настройки входа"/>
                                        </button>
                                    </a>
                                    <button className="top_menu_exit exit-button k_trainer_exit">Выйти</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </>
        );
    }
};

export default TrainerItem;
